import React from "react"
import { useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"

import Img from "gatsby-image"

import {
  SEO,
  Layout,
  Section,
  Container,
  Divider,
  TickList,
  Industries,
  Testimonials,
} from "../components"
import { Feature, Features, FeaturesSection } from "../components/styles/index"
import { Clients } from "../components/clients"

const areas = [
  "Business & Corporate Management",
  "Corporate Finance",
  "Project Management",
  "Corporate Legal",
  "Technology Consulting",
  "Government",
]

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query LogoImages {
      cbn_logo: file(relativePath: { eq: "CBN-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cad_logo: file(relativePath: { eq: "CAD-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chaad_logo: file(relativePath: { eq: "CHAAD-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeson_logo: file(relativePath: { eq: "EYESON-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      r2r_logo: file(relativePath: { eq: "R2R-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nlng_logo: file(relativePath: { eq: "NLNG-Logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nddc_logo: file(relativePath: { eq: "NDDC-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ssedc_logo: file(relativePath: { eq: "SSEDC-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lightville_img_1: file(relativePath: { eq: "lightville-image-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      software: file(relativePath: { eq: "software.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { lightville_img_1, software } = data

  return (
    <Layout location={location}>
      <SEO
        title="Home"
        description="LightVille is a consulting Hub of Premium Consultants. Lightville wants to help fix frictions in your business or organization"
      />

      <Fade delay={350}>
        <Clients clientLogos={data} data-aos="fade-up" />
      </Fade>

      <Fade bottom duration={350}>
        <Section>
          <Container>
            <p className="max-width-800">
              We have experts and a wealth of experience in various industries,
              all tailored towards bringing an unbeatable experience to our
              Clients. We have combined, over 15 years of experience in
              consulting. Amongst our team of professionals are Premium
              Management Consultants, Project Management Professionals with
              global recognition, Corporate Finance Experts, Corporate Legal
              Professionals.
            </p>
          </Container>
        </Section>
      </Fade>

      <FeaturesSection>
        <Container>
          <Fade delay={500}>
            <Features>
              <Feature>
                <Img
                  className="img"
                  width="100%"
                  fluid={lightville_img_1.childImageSharp.fluid}
                />
              </Feature>

              <Feature>
                <div>
                  <h2>
                    Advisory and Consulting Services at the{" "}
                    <span className="accent-gold">tip of your fingers</span>
                  </h2>
                  <p>
                    We have a standby team of Professionals in the following
                    areas of consulting
                  </p>
                  <TickList listItems={areas} />
                </div>
              </Feature>
            </Features>
          </Fade>

          <Fade delay={500}>
            <Features>
              <Feature className="flip">
                <div>
                  <h2>
                    Design and Sale of Business{" "}
                    <span className="accent-gold">Solutions/Apps</span> (SaaS
                    for B2B, B2C)
                  </h2>
                  <p>
                    Working with clients over the years has given us an insight
                    into how they can work efficiently. We’ve channelled these
                    findings into automated processes. We make these software
                    tailored to give an unmatchable experience to our
                    clientbase.
                  </p>
                </div>
              </Feature>

              <Feature>
                <Img
                  className="img"
                  width="100%"
                  fluid={software.childImageSharp.fluid}
                />
              </Feature>
            </Features>
          </Fade>
        </Container>
      </FeaturesSection>

      <Container>
        <Divider />
      </Container>

      <Industries />

      <Container>
        <Divider />
      </Container>

      <Fade>
        <Testimonials />
      </Fade>
    </Layout>
  )
}
export default IndexPage
