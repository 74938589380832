import styled from "@emotion/styled"
import { Flex } from "../../components"
import StyledSection from "../section/section-styles"
import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"

export const Features = styled(StyledFlex)`
  flex-direction: column;
  align-items: center;

  padding: var(--spacing-lg) 0;

  .img {
    margin-bottom: 1em;
  }

  @media screen and (min-width: 759px) {
    flex-direction: row-reverse;
  }
`

export const Feature = styled(StyledFlexChild)`
  flex: 1;
  min-width: 100%;

  @media (min-width: 759px) {
    margin-right: 0.8em;
    min-width: auto;
  }

  @media (max-width: 759px) {
    &.flip {
      order: 2;
    }
  }
`
export const FeaturesSection = styled(StyledSection)`
  background: var(--lightville-blue-lighter);
`
